"use client"

import { Button } from "@nextui-org/react"
import * as Sentry from "@sentry/nextjs"
import { useEffect } from "react"

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <div className="flex h-[calc(100vh-120px)] flex-col items-center justify-center px-6">
      <h1 className="text-6xl font-semibold text-gray-800">ERROR</h1>
      <p className="mb-8 mt-4 text-center text-gray-600">
        예기치 못한 에러가 발생했습니다.
      </p>
      <Button color="primary" onClick={() => reset()}>
        페이지 새로고침
      </Button>
    </div>
  )
}
